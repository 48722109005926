import React from 'react';
import '../assets/css/fonts.css';

import SEO from '../components/seo';
import Hero from '../components/hero';
import Footer from '../components/footer';
import Menu from '../components/menu';
import Hamburger from '../components/hamburger';
import Testimonials from '../components/carousel.testimonials';

import AniLink from 'gatsby-plugin-transition-link/AniLink';
import AOS from 'aos';
import 'aos/dist/aos.css';

import CardService from '../components/card';
import Client from '../components/client';
// import Hamburger from "../components/sticky_menu"

import style from '../assets/css/main.module.css';
import projectStyle from '../components/project.module.css';

import iconWebsite from '../assets/img/icon_website.svg';
import iconBranding from '../assets/img/icon_social.svg';
import iconNewsletter from '../assets/img/icon_email.svg';
import iconPhone from '../assets/img/icon_smartphone.svg';

import clientToyota from '../assets/img/clients/toyota.png';
import clientLexus from '../assets/img/clients/lexus.png';
import clientRightink from '../assets/img/clients/rightink.png';
import clientTeag from '../assets/img/clients/teag.png';
import clientEBQuad from '../assets/img/clients/ebquad.png';
import clientFlyingFish from '../assets/img/clients/flyingfish.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faLink } from '@fortawesome/free-solid-svg-icons';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
    };
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    AOS.init();
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.onScroll(isTop);
      }
    });
  }
  onScroll(isTop) {
    this.setState({ isTop });
  }
  componentDidUpdate() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }
  componentWillReceiveProps() {
    AOS.refresh();
  }

  render() {
    return (
      <div id="top">
        <SEO
          title="Agence digitale | Création de sites web | MDS Digital Agency"
          canonical="http://mds-digitalagency.be"
          description="Un projet digital? L’agence digitale MDS est la solution idéale. MDS vous aide à créer, gérer et développer vos plateformes digitales."
          keywords="agence digitale"
        />
        <div className={style.main}>
          <Menu />
          <div className={style.menu_mobile}>
            <Hamburger />
          </div>

          <Hero />

          <section id="services" className={[style.section_services, style.row].join(' ')}>
            <div className={style.container}>
              <h3 className={style.home_title}>Nos services</h3>
              <div className={style.home_services}>
                <CardService
                  cardTitle="Site web"
                  cardUrl="/services/siteweb/"
                  cardIcon={iconWebsite}
                  cardText="Nous réalisons votre site vitrine, votre plateforme de vente en ligne ou votre intranet/extranet."
                  animeOrder="0"
                />
                <CardService
                  cardTitle="Réseaux sociaux"
                  cardUrl="/services/reseaux-sociaux/"
                  cardIcon={iconBranding}
                  cardText="Nous sommes là pour vous accompagner dans la gestion de vos réseaux sociaux."
                  animeOrder="1"
                />
                <CardService
                  cardTitle="Application mobile"
                  cardUrl="/services/application-mobile/"
                  cardIcon={iconPhone}
                  cardText="MDS développe pour vous votre applications iOS et Android et vous accompagne pour tenir celle-ci à jour."
                  animeOrder="2"
                />
                <CardService
                  cardTitle="Newsletter"
                  cardUrl="/services/newsletter/"
                  cardIcon={iconNewsletter}
                  cardText="MDS gère pour vous toute votre campagne d'e-mailing et informe votre communauté de votre actualité."
                  animeOrder="3"
                />
              </div>
              {/* <div className={style.services_cta}>
                <AniLink cover to="/services/" bg="#fba515" style={{ textAlign: 'center' }}>
                  Voir tous nos services <FontAwesomeIcon icon={faCaretRight} />
                </AniLink>
              </div> */}
            </div>
          </section>
          <section id="projects" className={[style.section_works, style.row].join(' ')}>
            <div className={style.container}>
              <h3 className={style.home_title}>Réalisations</h3>
              <div>
                <AniLink
                  cover
                  bg="#fba515"
                  to="/realisations/toyota/"
                  className={projectStyle.box_link}
                >
                  <div className={projectStyle.container} data-aos="fade-up">
                    <div className={projectStyle.img_hover}>
                      <div className={projectStyle.img_hover_text}>
                        <FontAwesomeIcon className={projectStyle.img_hover_icon} icon={faLink} />
                        Découvrir le projet
                      </div>
                    </div>
                    <div className={[projectStyle.img_big, projectStyle.toyota].join(' ')} />
                    <div className={projectStyle.text_big}>
                      <h3>Toyota</h3>
                      <p>Chaque jour une information actualisée et un fonctionnement sans faille</p>
                    </div>
                  </div>
                </AniLink>
              </div>
              <div className={projectStyle.project_row}>
                <AniLink
                  cover
                  bg="#fba515"
                  to="/realisations/touch-english-and-go/"
                  className={projectStyle.box_link_small}
                >
                  <div
                    className={[projectStyle.container_small, projectStyle.container_left].join(
                      ' '
                    )}
                    data-aos="fade-right"
                  >
                    <div className={projectStyle.img_hover_small}>
                      <div className={projectStyle.img_hover_text}>
                        <FontAwesomeIcon className={projectStyle.img_hover_icon} icon={faLink} />
                        Découvrir le projet
                      </div>
                    </div>
                    <div className={[projectStyle.img_small, projectStyle.teag].join(' ')} />

                    <div className={projectStyle.text_small}>
                      <h3>Touch English And Go</h3>
                      <p>Développement de formations et tests en ligne</p>
                    </div>
                  </div>
                </AniLink>
                <AniLink
                  cover
                  bg="#fba515"
                  to="/realisations/right-ink/"
                  className={projectStyle.box_link_small}
                >
                  <div
                    className={[projectStyle.container_small, projectStyle.container_right].join(
                      ' '
                    )}
                    data-aos="fade-left"
                  >
                    <div className={[projectStyle.img_small, projectStyle.rightink].join(' ')}>
                      <div className={projectStyle.img_hover_small}>
                        <div className={projectStyle.img_hover_text}>
                          <FontAwesomeIcon className={projectStyle.img_hover_icon} icon={faLink} />
                          Découvrir le projet
                        </div>
                      </div>
                    </div>
                    <div className={projectStyle.text_small}>
                      <h3>Right Ink</h3>
                      <p>Retouche graphique et développement de nouvelles fonctionnalités</p>
                    </div>
                  </div>
                </AniLink>
              </div>
              <div className={style.services_cta}>
                <AniLink cover bg="#fba515" to="/realisations/" style={{ textAlign: 'center' }}>
                  Voir nos réalisations <FontAwesomeIcon icon={faCaretRight} />
                </AniLink>
              </div>
            </div>
          </section>

          <section id="clients" className={[style.section_client, style.row].join(' ')}>
            <div className={style.container}>
              <h3 className={style.home_title}>Ils nous font confiance</h3>
              <div className={style.client_flex}>
                <Client
                  clientName="Toyota"
                  clientUrl="http://www.toyota.be"
                  clientLogo={clientToyota}
                  animeOrder="0"
                />
                <Client
                  clientName="Lexus"
                  clientUrl="http://www.lexus.be"
                  clientLogo={clientLexus}
                  animeOrder="1"
                />
                <Client
                  clientName="Right Ink"
                  clientUrl="http://www.right-ink.com"
                  clientLogo={clientRightink}
                  animeOrder="2"
                />
                <Client
                  clientName="Touch English And Go"
                  clientUrl="https://www.touch-english-and-go.com/"
                  clientLogo={clientTeag}
                  animeOrder="3"
                />
                <Client
                  clientName="Flying Fish"
                  clientUrl="http://www.flyingfish.agency"
                  clientLogo={clientFlyingFish}
                  animeOrder="4"
                />
                {/* <Client
                  clientName="EB Quad"
                  clientUrl="https://www.ebquad.be/"
                  clientLogo={clientEBQuad}
                  animeOrder="5"
                /> */}
              </div>
            </div>
          </section>
          <section id="projects" className={[style.section_testimonials, style.row].join(' ')}>
            <div className={style.container}>
              <h3 className={style.home_title}>Nos clients parlent de nous</h3>
              <Testimonials />
            </div>
          </section>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Index;
