import React from 'react';
import heroStyle from './hero.module.css';
import style from '../assets/css/main.module.css';

export default () => (
  <div className={heroStyle.container}>
    <div className={heroStyle.container_text}>
      <span className={heroStyle.hero_title}>Un projet digital ?</span>
      <div className={heroStyle.hero_separator} />
      <div className={heroStyle.hero_text}>
        <h1 className={style.no_mobile}>L’agence digitale MDS est la solution idéale.</h1>
        <p className={style.no_mobile}>
          Vous souhaitez créer un site web ou une application, modifier un site existant, y ajouter
          des fonctionnalités, revoir son graphisme, gérer son contenu ou améliorer son
          référencement sur les moteurs de recherche?
        </p>
        <p>
          L’agence digitale MDS vous aide à créer, gérer et développer vos plateformes digitales
          tant sur le plan technique que celui de la communication.
        </p>
        <p className={style.no_mobile}>
          Faites appel à nos spécialistes investis, créatifs et efficaces qui ont à cœur de
          respecter les délais et les attentes des clients.
        </p>
      </div>
    </div>
  </div>
);
