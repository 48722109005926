import React from "react";
import Carousel from "nuka-carousel";
import carouselStyle from "../components/carousel.testimonials.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";

import testiFanny from "../assets/img/testimonials/fanny_wautelet.png";
import testiFish from "../assets/img/testimonials/flying_fish.png";
import testiToyota from "../assets/img/testimonials/toyota.png";
import testiTeag from "../assets/img/testimonials/teag.png";

class CarouselTestimonials extends React.Component {


  render() {
    return (
      <Carousel 
      className={ carouselStyle.container}
      wrapAround={true}
      enableKeyboardControls={true}
      speed={700}
      renderCenterLeftControls={({ previousSlide }) => (
        <button className={carouselStyle.prev} onClick={previousSlide}><FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon></button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button className={carouselStyle.next} onClick={nextSlide}><FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon></button>
      )}
      renderBottomCenterControls={ () => (
          <div className={carouselStyle.no_space}></div>
      )}
      >
        <div className={carouselStyle.slide}>
        <article className={carouselStyle.article_flex}>
          <div className={carouselStyle.thumbnail}>
                <img src={testiFish} alt="Flying Fish"/>
                
          </div>

          <div className={carouselStyle.testimonial}>
                <p>
                MDS nous assiste sur un gros projet international depuis quelques années, de manière professionnelle et efficace. Toujours disponible et réactif, c’est un partenaire sur lequel on peut toujours compter, les yeux fermés.                
                </p>
                <h3>Michael Notté – Digital Analytics Lead – Flying Fish (a brand of Yieldow SPRL)</h3>
          </div>
        </article>

          
        </div>
        <div  className={carouselStyle.slide}>
        <article className={carouselStyle.article_flex}>
          <div className={carouselStyle.thumbnail}>
                <img src={testiToyota} alt="Flying Fish"/>
                
          </div>

          <div className={carouselStyle.testimonial}>
                <p>
                J’ai le plaisir de travailler avec MDS depuis plusieurs années. Mathieu et ses collègues sont à l’écoute, efficaces et proactifs. Ils s’impliquent dans la gestion des projets de leurs clients et mettent tout en œuvre pour aboutir à un résultat optimal.
                </p>
                <h3>Audrey Zwings – Digital, CRM and Customer Experience Manager – Toyota & Lexus BELGIUM</h3>
          </div>
        </article>
        </div>
        <div  className={carouselStyle.slide}>
        <article className={carouselStyle.article_flex}>
          <div className={carouselStyle.thumbnail}>
                <img src={testiTeag} alt="Touch English And Go"/>
                
          </div>

          <div className={carouselStyle.testimonial}>
                <p>
                L'écoute, c'est ce qui caractérise le plus Mathieu et son équipe. Une qualité pas si fréquente dans le milieu informatique et pourtant essentielle à la réussite de tout projet !
                </p>
                <h3>Olivier Thaon – Founder – Touch English and Go</h3>
          </div>
        </article>
        </div>
      </Carousel>
    );
  }
}

export default CarouselTestimonials;
