import React from "react";
import clientStyle from "./client.module.css";

export default props => (
  <div className={clientStyle.container} data-aos="zoom-in-down"  data-aos-delay={props.animeOrder * 100}>
    <a href={props.clientUrl} target="_blank" rel="noopener noreferrer">
      <img
        className={clientStyle.img}
        src={props.clientLogo}
        alt={props.clientName}
      />
    </a>
  </div>
);
